export const examples = [
  {
    title: "Example: Convert Celcius to Fahrenheit",
    code: `// Read celcius temperature from sensor
var tempCelcius = Data.Read("NODE-ID", "SENSOR-KEY");
// Convert the temperature to fahrenheit
var tempFahrenheit = tempCelcius *  9/5 + 32;
// Write the new value to a new key
Data.Write("NODE-ID", "SENSOR-KEY", tempToFahrenheit);`,
  },
  {
    title: "Exemple: Average value",
    code: `// Get the data from three sensors
var temp1 = Data.Read("NODE-ID", "extTemp");
var temp2 = Data.Read("NODE-ID", "extTemp");
var temp3 = Data.Read("NODE-ID", "extTemp");

// Write the new value to a new key
Data.Write("NODE-ID", "averageTemp", (temp1 + temp2 + temp3) / 3);`,
  },
  {
    title: "Example: 4-20mA correction",
    code: `// Convert the analog input value 4-20mA to its corresponding sensor measurement value
const LOW_VALUE = 0;
const HIGH_VALUE = 200;

var inputValue = Data.Read("NODE-ID", "analog_in");

var result = (((HIGH_VALUE-LOW_VALUE)/(20 - 4)) * (inputValue - 4)) + LOW_VALUE;

// Write the new calculated value to a new key
Data.Write("NODE-ID", "distance", result);`,
  },
  {
    title: "Example: 0-10V correction",
    code: `// Convert the analog input value 0-10V to its corresponding sensor measurement value
const LOW_LIMIT = 0;
const HIGH_LIMIT = 200;

var inputValue = Data.Read("NODE-ID", "AnalogIn");
var result = (HIGH_LIMIT – LOW_LIMIT) * (inputValue / 10) + LOW_LIMIT;

Data.Write("NODE-ID", "pressure", result);`,
  },
  {
    title: "Example: Black ice detection",
    code: `var outsideTemp = Data.Read("NODE-ID", "temperature");
var humidity = Data.Read("NODE-ID", "humidity");
var roadTemp = Data.Read("NODE-ID", "temperature");

// Set the conditions of blackice
if (roadTemp < 2 && humidity > 75 && outsideTemp < 3) {
    Data.Write("NODE-ID", "black_ice", 1);
} else {
    Data.Write("NODE-ID", "black_ice", 0);
}`,
  },
  {
    title: "Example: Dew point calculation",
    code: `var temp = Data.Read("NODE-ID", "temperature");
var humid = Data.Read("NODE-ID", "humidity");

if (temp >= 0) {
    // water
    a = 7.5;
    b = 237.3;
} else {
    // ice
    a = 7.6;
    b = 240.7;
}

// First calculate saturation steam pressure for temperature
ssp = 6.1078 * Math.pow(10, (a * temp) / (b + temp));

// Steam pressure
sp = (humid / 100) * ssp;
v = Math.log(sp / 6.1078, 10);

// Dew point
dewPoint = Math.round((b * v) / (a - v), 1);

Data.Write("NODE-ID", "dew_point", dewPoint);`,
  },
  {
    title: "Example: Extract values from multi value string",
    code: `// Sample value string: "6&6&6&22 &&& 0m&7d&14d&21d"
var valueString = Data.ReadString("NODE-ID", "noise");
var valueArray = valueString.split("&");

// Write the new values to new keys
Data.Write("NODE-ID", "noise_now", valueArray[0]);
Data.Write("NODE-ID", "noise_7d", valueArray[1]);
Data.Write("NODE-ID", "noise_14d", valueArray[2]);
Data.Write("NODE-ID", "noise_21d", valueArray[3]);`,
  },
  {
    title: "Example: Calculate draft of ship",
    code: `// Read current analog value and offset
var currentValue = Data.Read("PORT_SENSOR", "Value");
var offset = Data.Read("PORT_SENSOR", "zero_offset");
// Convert analog value to depth using offset
let depth = ((currentValue -1000) / 160 - offset).toFixed(4);
// Save calculated depth to a virtual sensor
Data.Write("PORT_SENSOR", "depth", depth);
// Save depth without offset to a virtual sensor
Data.Write("PORT_SENSOR", "depth_raw", ((currentValue -1000) / 160).toFixed(4));

let avg = depth;
// Read number of values received this session 
// This gets reset by a different script connected to a dashboard button
let sessionValues = Data.Read("PORT_SENSOR", "session_values");
if (sessionValues > 0) {
    // Read all the values measured during this session
    let oldValues = Data.GetValues("PORT_SENSOR","depth", sessionValues);
    // Add up and divide the values to get an average depth
    for (let i = 0; i < oldValues.length; i++) avg = parseFloat(avg) + parseFloat(oldValues[i]);
    avg = (avg / (sessionValues + 1)).toFixed(4)
}
// Count up session values (maximum 4) as this script is only triggered to run by a new sensor value
if (sessionValues < 4) Data.Write("PORT_SENSOR", "session_values", sessionValues + 1);
// Save average depth
Data.Write("PORT_SENSOR", "depth_moving_avg", avg);
// Set new_data to 1 to signal a looping script which uses multiple sensors depth values for calculations
Data.Write("PORT_SENSOR", "new_data", 1);`,
  },
  {
    title: "Example: Calculate CO2 emissions",
    code: `// Get the data from two electricity meters
var kwh1 = Data.Read("NODE-ID", "Active Energy Import T1");
var kwh2 = Data.Read("NODE-ID2", "Active Energy Import T1");
var sum = kwh1 + kwh2;
// Write the new value to a new key
Data.Write("STATS_NODE", "sum", sum);
// Calculate CO2 emission in grams based on 91 grams CO2 per kWh
var co2_value = 91;
var co2_result = sum co2_value;
// Convert CO2 emission from grams to kilograms (1 kg = 1000 g)
var co2_result_in_kg = co2_result / 1000;
// Write the CO2 emission in kilograms to the specified key
Data.Write("STATS_NODE", "co2", co2_result_in_kg);`,
  },
];
