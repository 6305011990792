<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="$emit('dialog-toggle')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("script.helpTitle") }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container class="fill-height" fluid>
      <v-row align="start">
        <v-col cols="12" sm="12" md="6" class="py-0 pl-0 pr-1">
          <v-card class="pa-2">
            <v-main class="pt-0">
              <div v-for="(e, i) in scriptExamples" :key="i">
                <h3>{{ e.title }}</h3>
                <codemirror
                  :value="e.code"
                  :options="cmOptions"
                  >{{
                }}</codemirror>
                <v-divider></v-divider>
                <br />
              </div>
            </v-main>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="py-0 pr-0 pl-1">
          <v-card class="pa-0">
            <v-expansion-panels accordion multiple :value="openPanels">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Data</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-for="(f, i) in scriptDataFunctions"
                    :key="i"
                    style="display: grid"
                  >
                    <div class="text-h6 pt-1 pl-1" style="line-height: 1.5rem">
                      {{ f.name }}
                    </div>
                    <small class="pl-2">
                      {{ f.description }}
                    </small>
                    <v-subheader
                      inset
                      class="d-flex flex-column align-start px-0 pb-2 pt-1"
                      style="height: 100%"
                    >
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Example Input: </span>
                        <span>
                          {{ f.exampleInput }}
                        </span>
                      </div>
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Returns: </span>
                        <span>
                          {{ f.returns }}
                        </span>
                      </div>
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Expected Output: </span>
                        <span>
                          {{ f.exampleOutput }}
                        </span>
                      </div>
                    </v-subheader>
                    <v-divider v-if="i < scriptDataFunctions.length - 1" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>TimeHelper</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    style="display: grid"
                    v-for="(f, i) in scriptTimeFunctions"
                    :key="i"
                  >
                    <div class="text-h6 pt-1 pl-1" style="line-height: 1.5rem">
                      {{ f.name }}
                    </div>
                    <v-subheader
                      inset
                      class="d-flex flex-column align-start px-0 pb-2 pt-1"
                      style="height: 100%"
                    >
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Example Input: </span>
                        <span>
                          {{ f.exampleInput }}
                        </span>
                      </div>
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Returns: </span>
                        <span>
                          {{ f.returns }}
                        </span>
                      </div>
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Expected Output: </span>
                        <span>
                          {{ f.exampleOutput }}
                        </span>
                      </div>
                    </v-subheader>
                    <v-divider v-if="i < scriptTimeFunctions.length - 1" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Downlinks</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div style="display: grid">
                    <div class="text-h6 pt-1 pl-1" style="line-height: 1.5rem">
                      {{
                        "downlinks.Add(string deveui, string payload, int port, bool confirmed)"
                      }}
                    </div>
                    <small class="pl-2">
                      {{
                        "Enqueues a downlink to a node, payload format is hex without prefix."
                      }}
                    </small>
                    <v-subheader
                      inset
                      class="d-flex flex-column align-start px-0 pb-2 pt-1"
                      style="height: 100%"
                    >
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Example Input: </span>
                        <span>
                          {{
                            "downlinks.Add('70b3d54990e8702b', 'BF0001', 10, false);"
                          }}
                        </span>
                      </div>
                    </v-subheader>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>Modbus</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    style="display: grid"
                    v-for="(f, i) in modbusFunctions"
                    :key="i"
                  >
                    <div class="text-h6 pt-1 pl-1" style="line-height: 1.5rem">
                      {{ f.name }}
                    </div>
                    <v-subheader
                      inset
                      class="d-flex flex-column align-start px-0 pb-2 pt-1"
                      style="height: 100%"
                    >
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Example Input: </span>
                        <span>
                          {{ f.exampleInput }}
                        </span>
                      </div>
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Returns: </span>
                        <span>
                          {{ f.returns }}
                        </span>
                      </div>
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Expected Output: </span>
                        <span>
                          {{ f.exampleOutput }}
                        </span>
                      </div>
                    </v-subheader>
                    <v-divider v-if="i < modbusFunctions.length - 1" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header>
                  <h2>SO Functionality</h2>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <h4>Use With Caution!</h4>
                  <div
                    style="display: grid"
                    v-for="(f, i) in scriptSOFunctions"
                    :key="i"
                  >
                    <div class="text-h6 pt-1 pl-1" style="line-height: 1.5rem">
                      {{ f.name }}
                    </div>
                    <small class="pl-2">
                      {{ f.description }}
                    </small>
                    <v-subheader
                      inset
                      class="d-flex flex-column align-start px-0 pb-2 pt-1"
                      style="height: 100%"
                    >
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Example Input: </span>
                        <span>
                          {{ f.exampleInput }}
                        </span>
                      </div>
                      <div style="max-width: 100%; width: 100%">
                        <span class="font-weight-bold">Returns: </span>
                        <span>
                          {{ f.returns }}
                        </span>
                      </div>
                    </v-subheader>
                    <v-divider v-if="i < scriptSOFunctions.length - 1" />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { codemirror } from "vue-codemirror";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/monokai.css";
import { examples } from "@/components/script/Examples.js";

export default {
  name: "ExampleScripts",

  components: { codemirror },

  data() {
    return {
      openPanels: [0],
      cmOptions: {
        tabSize: 4,
        mode: "text/javascript",
        theme: "monokai",
        lineNumbers: true,
        line: true,
        readOnly: true,
      },

      scriptDataFunctions: [
        {
          name: "Data.Read(string deveui, string sensorKey)",
          returns: "double",
          exampleInput: "Data.Read('A81758FFFE0459FA', 'extTemp')",
          exampleOutput: "20",
          description: "Read the latest value from a sensor.",
        },
        {
          name: "Data.ReadString(string deveui, string sensorKey)",
          returns: "string",
          exampleInput: "Data.Read('A81758FFFE0459FA', 'message')",
          exampleOutput: "'Sensor error!'",
          description: "Read the latest (text) value from a sensor.",
        },
        {
          name: "Data.Write(string deveui, string sensorKey, double value)",
          returns: "nothing",
          exampleInput: "Data.Write('A81758FFFE0459FA', 'temp_fahrenheit', 66)",
          exampleOutput: "nothing",
          description:
            "Write a new value to a sensor. Creates a new virtual sensor if it does not exist.",
        },
        {
          name: "Data.Write(string deveui, string sensorKey, string value)",
          returns: "nothing",
          exampleInput: "Data.Write('A81758FFFE0459FA', 'display', 'Hello!')",
          exampleOutput: "nothing",
          description:
            "Write a new value to a sensor. Creates a new virtual sensor if it does not exist.",
        },
        {
          name: "Data.GetDifference(string deveui, string sensorKey, DateTime date)",
          returns: "double",
          exampleInput:
            "Data.GetDifference('A81758FFFE0459FA', 'extTemp', '2021-11-10 08:26:10')",
          exampleOutput: "-30",
          description:
            "Get the difference between latest value and oldest value between now and the specified time.",
        },
        {
          name: "Data.GetMax(string deveui, string sensorKey, DateTime date)",
          returns: "double",
          exampleInput:
            "Data.GetMax('A81758FFFE0459FA', 'extTemp', '2021-11-10 08:26:10')",
          exampleOutput: "100",
          description:
            "Get the highest value recorded since the specified time.",
        },
        {
          name: "Data.GetMin(string deveui, string sensorKey, DateTime date)",
          returns: "double",
          exampleInput:
            "Data.GetMin('A81758FFFE0459FA', 'extTemp', '2021-11-10 08:26:10')",
          exampleOutput: "50",
          description:
            "Get the lowest value recorded since the specified time.",
        },
        {
          name: "Data.GetValues(string deveui, string sensorKey, int amount)",
          returns: "double array",
          exampleInput: "Data.GetValues('A81758FFFE0459FA', 'extTemp', 4)",
          exampleOutput: "[85, 50, 90, 100]",
          description: "Get latest [amount] values.",
        },
        {
          name: "Data.GetStringValues(string deveui, string sensorKey, int amount)",
          returns: "string array",
          exampleInput:
            "Data.GetStringValues('A81758FFFE0459FA', 'message', 5)",
          exampleOutput: "['OK', 'OK', 'Sensor error!', 'Sensor error!', 'OK']",
          description: "Get latest [amount] text values.",
        },
        {
          name: "Data.Average(string deveui, string sensorKey, int amount)",
          returns: "double",
          exampleInput: "Data.Average('A81758FFFE0459FA', 'extTemp', 5)",
          exampleOutput: "5",
          description: "Get the average of the last [amount] values.",
        },
        {
          name: "Data.AverageSinceDate(string deveui, string sensorKey, DateTime date)",
          returns: "double",
          exampleInput:
            "Data.AverageSinceDate('A81758FFFE0459FA', extTemp, '2021-04-10 08:26:10')",
          exampleOutput: "14",
          description:
            "Get the average of all values since the specified date.",
        },
        {
          name: "Data.GetTrendHour(string deveui, string key, DateTime date, int hours)",
          returns: "double array",
          exampleInput:
            "Data.GetTrendHour('A81758FFFE0459FA', extTemp, '2021-04-10 08:26:10', 1)",
          exampleOutput: "[-2, -2, -1, 3, 2]",
          description:
            "Get the change in value for every [hours] hours since date.",
        },
        {
          name: "Data.GetTrendDay(string deveui, string key, DateTime date, int days)",
          returns: "double array",
          exampleInput:
            "Data.GetTrendDay('A81758FFFE0459FA', extTemp, '2021-04-02 08:26:10', 1)",
          exampleOutput: "[-1, -2, -2, 1, 2, -5, 3, 3]",
          description:
            "Get the change in value for every [days] days since date.",
        },
        {
          name: "Data.GetTrendHourAvg(string deveui, string key, DateTime date, int hours)",
          returns: "double",
          exampleInput:
            "Data.GetTrendHourAvg('A81758FFFE0459FA', extTemp, '2021-04-10 08:26:10', 1)",
          exampleOutput: "0",
          description: "Get the average change every [hours] hours since date.",
        },
        {
          name: "Data.GetTrendDayAvg(string deveui, string key, DateTime date, int days)",
          returns: "double",
          exampleInput:
            "Data.GetTrendDayAvg('A81758FFFE0459FA', extTemp, '2021-04-02 08:26:10', 1)",
          exampleOutput: "-0.1",
          description: "Get the average change every [days] days since date.",
        },
      ],

      modbusFunctions: [
        {
          name: "Modbus.WriteHolding(Guid 'MASTER-ID', byte slave, ModbusValueType valueType, ushort register, string value, ModbusByteOrder byteOrder = ModbusByteOrder.DCBA)",
          returns: "nothing",
          exampleInput:
            "Modbus.WriteHolding('3c5ef817-a180-4ecf-97fa-1242bc9e54a9', 1, Modbus.ValueType.FLOAT, 40, '21.5', Modbus.ByteOrder.DCBA)",
          exampleOutput: "nothing",
          description: "Write to Modbus holding register",
        },

        {
          name: "Modbus.ValueType",
          returns: "Enum value",
          exampleInput: "Modbus.ValueType.FLOAT",
          exampleOutput: "0",
          description:
            "Enum helper for modbus values supported values FLOAT, DOUBLE, INT16, UINT16, INT32, UINT32 & BOOL",
        },

        {
          name: "Modbus.ByteOrder",
          returns: "Enum value",
          exampleInput: "Modbus.ByteOrder.DCBA",
          exampleOutput: "0",
          description:
            "Enum helper for modbus byte order supported values ABCD, DCBA, BADC & CDAB",
        },
      ],

      scriptTimeFunctions: [
        {
          name: "TimeHelper.DaysAgo(int days)",
          returns: "timestamp as string",
          exampleInput: "TimeHelper.DaysAgo(3)",
          exampleOutput: "'2021-11-07 08:26:10'",
        },
        {
          name: "TimeHelper.HoursAgo(int hours)",
          returns: "timestamp as string",
          exampleInput: "TimeHelper.HoursAgo(1)",
          exampleOutput: "'2021-11-10 07:26:10'",
        },
        {
          name: "TimeHelper.MinutesAgo(int minutes)",
          returns: "timestamp as string",
          exampleInput: "TimeHelper.MinutesAgo(15)",
          exampleOutput: "'2021-11-10 08:11:10'",
        },
      ],

      scriptSOFunctions: [
        {
          name: "SO.SendSMS(string number, string message)",
          returns: "nothing",
          exampleInput: "SO.SendSMS('+46000000000', 'Test Message!')",
          description:
            "Send a text message to the specified phone number from SO (number must use the format in the example).",
        },

        {
          name: "SO.SendSMS(string[] numbers, string message)",
          returns: "nothing",
          exampleInput:
            "SO.SendSMS(['+46000000000', '+46000000001'], 'Test message!')",
          description: "Send a text message to a list of phone numbers.",
        },

        {
          name: "SO.SendMail(string address, string message)",
          returns: "nothing",
          exampleInput: "SO.SendMail('admin@iot-ab.se', 'Test message!');",
          description: "Send an email to the specified address.",
        },

        {
          name: "SO.SendMail(string[] addresses, string message)",
          returns: "nothing",
          exampleInput:
            "SO.SendMail(['admin@iot-ab.se', 'adam@iot-ab.se'], 'Test message!');",
          description: "Send an email to the each address in a list",
        },
      ],
    };
  },

  computed: {
    scriptExamples() {
      return examples;
    },
  },
};
</script>
<style>
.CodeMirror {
  height: 250px;
}
</style>
